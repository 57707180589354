import React from "react";
const DaysNavBar = ({ date, onDayIncrease, onDayDecrease }) => {
  const currentDate = new Date();
  const monthName = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  return (
    <div className="day-nav-bar">
      <div
        className="nav-bar-arrow"
        style={{ marginRight: "10px", cursor: "pointer" }}
        onClick={onDayDecrease}>
        <i className="bi bi-arrow-left-circle"></i>
      </div>
      <div className="nav-bar-date-container">
        {date.getDate()} {monthName[date.getMonth()]}
      </div>
      <div className="nav-bar-arrow">
        {date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear() ? (
          ""
        ) : (
          <div onClick={onDayIncrease}>
            <i className="bi bi-arrow-right-circle"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default DaysNavBar;
