import React from 'react';
import IMG from "../assets/images/moon.webp"
const Loader = () => {
	return (
		<div className="AppContainer night-mode">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 40px",
              justifyContent: "center",
              alignItems: "center",
							marginBottom: "80px",
							width: "100%",
							height: "100%",
            }}>
            <img
              src={IMG}
              alt=""
              style={{
                zIndex: 0,
                borderRadius: "50%",
                margin: "0 auto",
                width: "200px",
                marginBottom: "40px",
              }}
            />
            <p>Загрузка...</p>
          </div>
        </div>
	 );
}

export default Loader;
