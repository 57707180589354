import React from 'react';

const StatisticPage = () => {
	return (
		<div className="AppContainer night-mode">
	<h1>Статистика</h1>
	</div> );
}

export default StatisticPage;
