import React, {useState } from "react";
import Modal from "./Modal";
import actingStatus from "../fake-api/actingStatus";
const DaytimeSleepAddInfoBlock = ({ onStart, label, view }) => {
	const [data, setData] = useState({})
  const handleChange = ({target}) => {
		setData((prevState) => ({...prevState,[target.name]: target.value}))
  };
  const [showModal, setShowModal] = useState(false);
	const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={view} style={{minHeight: "400px", height: "auto"}}>
      <div>
        <div className="text">Поведение при укладывании и засыпании <i className="bi bi-question-circle-fill" style={{cursor: "pointer"}} onClick={() => {setShowModal(true)}}></i></div>
        <div className="acting-status-container">
          {actingStatus.map((item) => {
            return (
              <div key={item.id}>
                <input
                  className="acting"
                  type="radio"
                  name="actingId"
                  id={"act_" + item.id}
                  value={item.img}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor={"act_" + item.id}>
                  <i className={"bi bi-emoji-" + item.img}></i>
                </label>
              </div>
            );
          })}
        </div>
      </div>
			<div className="dop-info-container">
			<label htmlFor="time">Времени ушло на засыпание в мин:</label>
			<input type="number" name="time" id="time" onChange={handleChange} />
			<label htmlFor="time">В каком настроении проснулся:</label>
			<input type="text" name="wakeUpMood" id="wakeUpMood" onChange={handleChange} />
      <label htmlFor="notes">Заметки:</label>
      <textarea name="notes" id="notes" type="text" onChange={handleChange} cols="20" rows="4" />
			</div>
      <div className="btn" onClick={() => onStart(data || "")}>
        {label}
      </div>
			{showModal && (
        <Modal onClose={handleToggleModal}>
          {actingStatus.map((item) => {
            return (
							<div style={{display: "flex", flexDirection: "row", marginBottom: "20px", borderBottom: "1px solid rgba(255,255,255, 0.1", paddingBottom: "20px"}} key={"mood_"+item.id}>
								<div style={{fontSize: "50px", marginRight: "20px"}}><i className={"bi bi-emoji-" + item.img}></i></div>
								<div style={{textAlign: "justify", fontSize: "16px", textTransform: "none"}}>{item.description}</div>
							</div>
						)})}
        </Modal>
      )}
    </div>
  );
};

export default DaytimeSleepAddInfoBlock;
