import React, { useState, useEffect } from "react";
import todayTimestamp from "../components/utils/todayTimestamp";
import IMG from "../assets/images/moon.webp"
const ChildInfo = () => {
  const [data, setData] = useState(JSON.parse(localStorage.getItem("journal-user")) || {name: "", pdr: ""});
	const handleChange = ({target}) => {
		setData((prevState) => ({...prevState, [target.name]: target.value }))
	}
	const monthName = ["jan", "feb", "mar", "apr","may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
	const date = data.pdr.split("-")
	const ageDays = (todayTimestamp() - new Date(date[2]+ " " + monthName[date[1]-1]+" "+ date[0]).getTime())/1000/60/60/24
	useEffect(() => {
		localStorage.setItem("journal-user", JSON.stringify(data));
	}, [data])
  return (
    <div className="user-container">
      <img
        src={IMG}
        alt=""
        style={{
          zIndex: 0,
          borderRadius: "50%",
          margin: "0 auto",
          width: "200px",
          marginBottom: "40px",
        }}
      />
      <label htmlFor="name">Имя</label>
      <input value={data.name} name="name" id="name" type="text"  onChange={handleChange} />
      <label htmlFor="pdr">
        ПДР
        <br />
        (предполагаемая дата родов)
      </label>
      <input value={data.pdr} name="pdr" id="pdr" type={"date" || "text"} onChange={handleChange} placeholder="yyyy-mm-dd"/>
			<p>Возраст: {Number.parseInt(ageDays / 365)} лет {Number.parseInt(ageDays%365/30)} мес</p>
    </div>
  );
};

export default ChildInfo;
