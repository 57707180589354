import React from "react";
import JournalPage from "./components/pages/JournalPage";
import StatisticPage from "./components/pages/StatisticPage";
import UserPage from "./components/pages/UserPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RecPage from "./components/pages/RecPage";
import NavBar from "./components/NavBar";

function App() {
  return (
<div className="page-container">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<JournalPage />} />
          <Route path="/statistic" element={<StatisticPage />} />
          <Route path="/user" element={<UserPage />} />
          <Route path="/journal/:date/:index" element={<JournalPage />} />
          <Route path="/journal/:date/editDay" element={<JournalPage />} />
          <Route path="/journal/:date/editNight" element={<JournalPage />} />
          <Route path="/journal" element={<JournalPage />} />
          <Route path="/recomendations" element={<RecPage />} />
					<Route path="/*" element={<JournalPage />} />
        </Routes>
      </BrowserRouter>
			</div>
  );
}

export default App;
