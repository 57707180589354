import React from 'react';
import todayTimestamp from '../utils/todayTimestamp';

const RecPage = () => {
	const user = JSON.parse(localStorage.getItem("journal-user")) || {name: "", pdr: ""}
	const monthName = ["jan", "feb", "mar", "apr","may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
	const date = user.pdr.split("-")
	const ageInMonth = (todayTimestamp() - new Date(date[2]+ " " + monthName[date[1]-1]+" "+ date[0]).getTime())/1000/60/60/24/30
	return (
		<div className="AppContainer night-mode">
	{user.pdr === "" ? <h1>Укажите ПДР ребенка, чтобы увидеть рекомендации, актуальные для его возраста</h1>:<h1>Рекомендации по сну</h1>}
	<div>
		<div>Начало дня:</div>
		<div>
			{ageInMonth < 4 && <>с 7:00 до 11:00</>}
			{ageInMonth >= 4 && ageInMonth < 9 && <>с 6:00 до 7:30</>}

		</div>
	</div>
	</div>

	);
}

export default RecPage;
