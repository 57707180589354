import React, { useState, useEffect } from "react";
import DaysNavBar from "../DaysNavBar";
import YellowButton from "../GlowingButton";
import BlueButton from "../BlueButton";
import api from "../../fake-api";
import DaySleepInfoBlock from "../DaytimeSleepAddInfoBlock";
import minutes from "../utils/minutes";
import hours from "../utils/hours";
import todayTimestamp from "../utils/todayTimestamp";
import Modal from "../Modal";
import Loader from "../Loader";
import MOON from "../../assets/images/moon.webp";
import FOX from "../../assets/images/fox2.webp";

function JournalPage() {
  const [isDaySleep, setIsDaySleep] = useState(false);
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isDayStarted, setIsDayStarted] = useState(
    JSON.parse(localStorage.getItem("isDayStarted")) || false
  );

  useEffect(() => {
    api.journal.fetchAll().then((journalData) => setData(journalData));
  }, []);

  const currentDayData = data.filter((item) => {
    return (
      new Date(item.date.year, item.date.month, item.date.day).getTime() ===
      currentDate.getTime()
    );
  })[0];

  const handleStartDay = () => {
    if (data[data.length - 1].date.day !== today.getDate()) {
      const newDayData = {
        date: {
          day: today.getDate(),
          month: today.getMonth(),
          year: today.getFullYear(),
        },
        dayStart: {},
        daySleeps: [],
        nightStart: null,
        nightNotes: "",
      };
      api.journal.add(newDayData);
      api.journal.fetchAll().then((journalData) => setData(journalData));
    }
    const newData = { dayStart: { h: hours(), m: minutes() } };
    api.journal.update(todayTimestamp(), newData);
    setIsDayStarted(true);
    localStorage.setItem("isDayStarted", "true");
  };
  const [sleepData, setSleepData] = useState({
    startTime: {},
    endTime: {},
    actingId: "",
    notes: "",
  });
  const handleToggleDaySleep = (data) => {
    if (!isDaySleep) {
      setSleepData((prevState) => ({
        ...prevState,
        startTime: { h: hours(), m: minutes() },
      }));
      setIsDaySleep(!isDaySleep);
    }
    if (isDaySleep) {
      sleepData.endTime["h"] = hours();
      sleepData.endTime["m"] = minutes();
      const newDayDream = currentDayData.daySleeps;
      newDayDream.push({ ...sleepData, ...data });
      const newData = { ...currentDayData, daySleeps: newDayDream };
      api.journal.update(todayTimestamp(), newData);
      setIsDaySleep(!isDaySleep);
      setSleepData({ startTime: {}, endTime: {} });
    }
  };
  const handleStartNight = () => {
    const newData = { nightStart: { h: hours(), m: minutes() } };
    api.journal.update(todayTimestamp(), newData);
    setData(JSON.parse(localStorage.getItem("journal")));
    setIsDayStarted(false);
    localStorage.setItem("isDayStarted", "false");
  };
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };
  const handleDayDecrease = (e) => {
    let newYear;
    let newMonth;
    let newDate;
    if (currentDate.getDate() > 1) {
      newDate = currentDate.getDate() - 1;
      newYear = currentDate.getFullYear();
      newMonth = currentDate.getMonth();
    } else {
      if (currentDate.getMonth() > 0) {
        newMonth = currentDate.getMonth() - 1;
        if (
          newMonth === 3 ||
          newMonth === 5 ||
          newMonth === 8 ||
          newMonth === 10
        ) {
          newDate = 30;
          newYear = currentDate.getFullYear();
        } else if (currentDate.getFullYear() % 4 === 0 && newMonth === 1) {
          newDate = 29;
          newYear = currentDate.getFullYear();
        } else {
          newDate = 28;
          newYear = currentDate.getFullYear();
        }
      } else {
        newMonth = 11;
        newYear = currentDate.getFullYear() - 1;
        newDate = 31;
      }
    }
    setCurrentDate(new Date(newYear, newMonth, newDate));
  };

  const handleDayIncrease = (e) => {
    let newYear;
    let newMonth;
    let newDate;
    if (
      currentDate.getFullYear() % 4 === 0 &&
      currentDate.getMonth() === 1 &&
      currentDate.getDate() === 29
    ) {
      newYear = currentDate.getFullYear();
      newMonth = currentDate.getMonth() + 1;
      newDate = 1;
    } else if (
      currentDate.getFullYear() % 4 !== 0 &&
      currentDate.getMonth() === 1 &&
      currentDate.getDate() === 28
    ) {
      newYear = currentDate.getFullYear();
      newMonth = currentDate.getMonth() + 1;
      newDate = 1;
    } else if (
      (currentDate.getMonth() === 0 ||
        currentDate.getMonth() === 2 ||
        currentDate.getMonth() === 4 ||
        currentDate.getMonth() === 6 ||
        currentDate.getMonth() === 7 ||
        currentDate.getMonth() === 9) &&
      currentDate.getDate() === 31
    ) {
      newYear = currentDate.getFullYear();
      newMonth = currentDate.getMonth() + 1;
      newDate = 1;
    } else if (currentDate.getMonth() === 11 && currentDate.getDate() === 31) {
      newYear = currentDate.getFullYear() + 1;
      newMonth = 0;
      newDate = 1;
    } else {
      newYear = currentDate.getFullYear();
      newMonth = currentDate.getMonth();
      newDate = currentDate.getDate() + 1;
    }
    setCurrentDate(new Date(newYear, newMonth, newDate));
  };

  if (data.length === 0) {
    return <Loader />;
  }

  return (
    <>
      <div className="AppContainer night-mode">
        <DaysNavBar
          date={currentDate}
          onDayIncrease={handleDayIncrease}
          onDayDecrease={handleDayDecrease}
        />
        {currentDate.getTime() ===
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          ).getTime() &&
          (isDayStarted === false && currentDayData === undefined ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 40px",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "20px",
                }}>
                <img
                  src={MOON}
                  alt=""
                  style={{
                    zIndex: 0,
                    borderRadius: "50%",
                    margin: "0 auto",
                    width: "200px",
                    marginBottom: "40px",
                  }}
                />
                <p>Данных за этот день нет. </p>
              </div>
              <YellowButton
                onStart={handleStartDay}
                label="Начать день"
                view="button"
              />
            </>
          ) : (
            <>
              {currentDayData === undefined ? (
                <Loader />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginBottom: "20px",
                    }}>
                    {currentDayData.nightStart === null
                      ? "Хорошего дня!"
                      : "Сладких снов!"}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#272d33",
                      width: "500px",
                      maxWidth: "100%",
                      zIndex: "1000",
                    }}>
                    {!isDaySleep && currentDayData.nightStart === null && (
                      <YellowButton
                        onStart={handleToggleDaySleep}
                        label="Начать дневной сон"
                        view="day-sleep-button"
                      />
                    )}
                    {isDaySleep && (
                      <DaySleepInfoBlock
                        onStart={handleToggleDaySleep}
                        label="Завершить дневной сон"
                        view="day-sleep-button"
                      />
                    )}
                    {!isDaySleep && currentDayData.nightStart === null && (
                      <BlueButton
                        onStart={handleStartNight}
                        label="Начать ночь"
                        disabled={isDaySleep ? true : false}
                      />
                    )}
                  </div>{" "}
                </div>
              )}
            </>
          ))}

        {currentDayData && (
          <div style={{ backgroundColor: "#35251d" }}>
            <img src={FOX} alt="" style={{ width: "100%", zIndex: 0 }} />
            <div style={{ position: "relative", bottom: "30px" }}>
              <div className="day-dream-container">
                <div className="info">
                  День начался в{" "}
                  {currentDayData.dayStart.h < 10
                    ? "0" + currentDayData.dayStart.h
                    : currentDayData.dayStart.h}
                  :
                  {currentDayData.dayStart.m < 10
                    ? "0" + currentDayData.dayStart.m
                    : currentDayData.dayStart.m}
                </div>
                <div className="edit">
                  <i className="bi bi-pencil"></i>
                </div>
              </div>
              {currentDayData.daySleeps.map((son, i) => {
                return (
                  <div key={"dnevnoj-son-" + i} className="day-dream-container">
                    <div className="info">
                      <p>Дневной сон №{i + 1}</p>
                      <p>
                        {son.startTime.h}:
                        {son.startTime.m < 10
                          ? "0" + son.startTime.m
                          : son.startTime.m}{" "}
                        - {son.endTime.h}:
                        {son.endTime.m < 10
                          ? "0" + son.endTime.m
                          : son.endTime.m}
                      </p>
                      <p>
                        Продолжительность:{" "}
                        {(new Date(
                          currentDayData.date.year,
                          currentDayData.date.month,
                          currentDayData.date.day,
                          son.endTime.h,
                          son.endTime.m
                        ).getTime() -
                          new Date(
                            currentDayData.date.year,
                            currentDayData.date.month,
                            currentDayData.date.day,
                            son.startTime.h,
                            son.startTime.m
                          ).getTime()) /
                          1000 /
                          60}{" "}
                        мин{" "}
                      </p>
                      {son.time && (
                        <p>Времени ушло на засыпание: {son.time} мин</p>
                      )}
                      {son.wakeUpMood && (
                        <p>Настроение при просыпании: {son.wakeUpMood}</p>
                      )}
                      {son.notes && <p>Заметки: {son.notes}</p>}
                    </div>
                    {son.actingId && (
                      <div className="edit">
                        <i className={"bi bi-emoji-" + son.actingId}></i>
                      </div>
                    )}
                    <div
                      className="edit"
                      onClick={() => {
                        setShowModal(true);
                      }}>
                      <i className="bi bi-pencil"></i>
                    </div>
                  </div>
                );
              })}
              {currentDayData.nightStart !== null && (
                <div className="day-dream-container">
                  <div className="info">
                    Ночь началась в{" "}
                    {currentDayData.nightStart.h < 10
                      ? "0" + currentDayData.nightStart.h
                      : currentDayData.nightStart.h}
                    :
                    {currentDayData.nightStart.m < 10
                      ? "0" + currentDayData.nightStart.m
                      : currentDayData.nightStart.m}
                  </div>
                  <div
                    className="edit"
                    onClick={() => {
                      setShowModal(true);
                    }}>
                    <i className="bi bi-pencil"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {!currentDayData && currentDate.getTime() !== todayTimestamp() && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0 40px",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "80px",
              }}>
              <img
                src={MOON}
                alt=""
                style={{
                  zIndex: 0,
                  borderRadius: "50%",
                  margin: "0 auto",
                  width: "200px",
                  marginBottom: "40px",
                }}
              />
              <p>Данных за этот день нет. </p>
            </div>
          </>
        )}
      </div>
      {showModal && (
        <Modal onClose={handleToggleModal}>
          <h1>Форма для редактирования записи</h1>
        </Modal>
      )}
    </>
  );
}

export default JournalPage;
