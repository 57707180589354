import React from 'react';
const RegularButton = ({text, onClick}) => {
	return (
		<div style={{width: "350px", maxWidth: "70%", margin: "0 auto"}}>
		<button style={{width: "100%", padding: "20px", marginTop: "15px", borderRadius: "50px", textTransform: "uppercase", color: "#888", backgroundColor: "rgba(0,0,0, 0.2", border: "0", fontSize: "16px", cursor: "pointer"}} onClick={onClick}>
			{text}
		</button>
		</div>
	 );
}

export default RegularButton;
