import React from 'react';
const Modal = ({onClose, children}) => {

	return (
		<div className='overlay'>
		<div className='modal-window'>
			<div className='close-btn' onClick={onClose}><i className="bi bi-x-circle"></i></div>
			<div className='content-container'>{children}</div>
		</div>
		</div>
	 );
}

export default Modal;
