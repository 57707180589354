import React, { useState } from "react";
import ChildInfo from "../ChildInfo.jsx";
import RegularButton from "../ui/regularButton.jsx";

const UserPage = () => {
	const handleDelJournal = () => {
		localStorage.removeItem('journal');
		localStorage.removeItem('isDayStarted');
		window.location.reload(false)

	}
	const handleDelUser = () => {
		localStorage.removeItem('journal-user');
		window.location.reload(false)
	}
  const [child] = useState({ name: "Лев", pdr: "August 15 2009" });
    return (
			<div className="AppContainer night-mode">
		<ChildInfo name={child.name} pdr={child.pdr} />
		<div style={{marginTop: "40px"}}>
		<RegularButton text="Очистить дневник" onClick={handleDelJournal} />
		<RegularButton text="Очистить данные пользователя" onClick={handleDelUser} />
		</div>
		</div>)
};

export default UserPage;
