const journal = [
  {
    date: { day: 22, month: 0, year: 2023 },
    dayStart: { h: 6, m: 23 },
    daySleeps: [
      { startTime: { h: 7, m: 0 }, endTime: { h: 8, m: 36 }, actingId: 2, notes: ""},
      { startTime: { h: 10, m: 10 }, endTime: { h: 12, m: 0 }, actingId: 2, notes: ""},
      { startTime: { h: 14, m: 0 }, endTime: { h: 14, m: 30 }, actingId: 2, notes: ""},
    ],
		nightStart: { h: 20, m: 4 },
		nightNotes: "",
  },
	{
    date: { day: 24, month: 0, year: 2023 },
    dayStart: { h: 6, m: 55 },
    daySleeps: [
      { startTime: { h: 8, m: 0 }, endTime: { h: 9, m: 36 }, actingId: 2, notes: ""},
      { startTime: { h: 11, m: 10 }, endTime: { h: 13, m: 0 }, actingId: 2, notes: ""},
      { startTime: { h: 15, m: 0 }, endTime: { h: 15, m: 30 }, actingId: 2, notes: ""},
    ],
		nightStart: { h: 20, m: 4 },
		nightNotes: "",
  },
	{
    date: { day: 23, month: 0, year: 2023 },
    dayStart: { h: 7, m: 23 },
    daySleeps: [
      { startTime: { h: 9, m: 0 }, endTime: { h: 10, m: 36 }, actingId: 2, notes: ""},
      { startTime: { h: 12, m: 10 }, endTime: { h: 14, m: 0 }, actingId: 2, notes: ""},
      { startTime: { h: 16, m: 0 }, endTime: { h: 16, m: 30 }, actingId: 2, notes: ""},
    ],
		nightStart: { h: 20, m: 4 },
		nightNotes: "",
  }
];

if (!localStorage.getItem("journal")) {
  localStorage.setItem("journal", JSON.stringify(journal));
}
const fetchAll = () =>
  new Promise((resolve) => {
    window.setTimeout(function () {
      resolve(JSON.parse(localStorage.getItem("journal")));
    }, 1000);
  });

const update = (date, data) =>
  new Promise((resolve) => {
    const journal = JSON.parse(localStorage.getItem("journal"));
    const dayIndex = journal.findIndex((d) => new Date(d.date.year, d.date.month, d.date.day).getTime() === date);
    journal[dayIndex] = { ...journal[dayIndex], ...data };
    localStorage.setItem("journal", JSON.stringify(journal));
    resolve(journal[dayIndex]);
  });

	const add = (data) =>
	new Promise((resolve) => {
    const journal = JSON.parse(localStorage.getItem("journal"));
    const dayIndex = journal.length;
    journal[dayIndex] = { ...data };
    localStorage.setItem("journal", JSON.stringify(journal));
    resolve(journal[dayIndex]);
  });
const getByDate = (date) =>
  new Promise((resolve) => {
    window.setTimeout(function () {
      resolve(
        JSON.parse(localStorage.getItem("journal")).find(
          (d) => new Date(d.date.year, d.date.month, d.date.day).getTime() === new Date(date).getTime()
        )
      );
    }, 1000);
  });

export default {
  fetchAll,
  getByDate,
  update,
	add
};
