import React from "react";
import { Link } from "react-router-dom";
const NavBar = () => {
  return (
    <div className="nav-bar-container">
      <div className="nav-bar-item"><Link to="/"><i className="bi bi-journal-text"></i><br />Дневник</Link></div>
      <div className="nav-bar-item"><Link to="/user"><i className="bi bi-person-gear"></i><br />Профиль</Link></div>
      <div className="nav-bar-item"><Link to="/statistic"><i className="bi bi-graph-down"></i><br />Статистика</Link></div>
      <div className="nav-bar-item"><Link to="/recomendations"><i className="bi bi-patch-question"></i><br />Рекомендации</Link></div>
    </div>
  );
};

export default NavBar;
